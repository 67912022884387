<template>
  <v-app id="inspire">
    <v-app-bar app color="blue-grey" dark>
      <v-toolbar-title>Haensli Cup - {{ this.$route.name }} </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid>
        <div id="app">
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data: () => ({ drawer: null, group: null }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss">
@media print {
  .v-content {
    padding: 0 !important;
  }
  .v-toolbaar {
    display: none;
  }
}

.v-main {
  background: url("../src/assets/haensli-transparent.png") right bottom
    no-repeat;
  background-size: 10%;
  height: 10;
}
.vgt-fixed-header {
  z-index: 5 !important;
}
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#dataTable {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
